import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {
  Eviction,
  EvictionSearch,
  EvictionPrintPopupModel,
  StepCompletionModel,
  Results,
  ClientAnswers,
  Filters, User, EvictionUpdate, CourtTask
} from '@ee/common/models';
import {DateTime} from 'luxon';
import {IServerSideGetRowsRequest} from 'ag-grid-enterprise';
import {Params} from '@angular/router';

@Injectable({providedIn: 'root'})
export class EvictionService {

  private readonly dateFormat = 'YYYY-MM-DD';

  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  findEvictions(request: IServerSideGetRowsRequest, includeFilters: boolean, fields: string[] = [],
    status: 'open' | 'closed' | 'both', clientId: string | undefined = undefined, selectedAttorney: User | undefined):
    Observable<Results<Eviction>> {
    let queryParams = `include-filters=${includeFilters}`;

    if (status === 'open') {
      queryParams += '&open=true';
    } else if (status === 'closed') {
      queryParams += '&closed=true';
    }

    if (clientId) {
      queryParams += `&client=${clientId}`;
    }

    if (fields.length) {
      queryParams += `&fields=${fields.join(',')}`;
    }

    if (selectedAttorney?.id) {
      queryParams += `&attorney=${selectedAttorney.id}`;
    }

    return this.http.post<Results<Eviction>>(
      this.environment.api_prefix + `api/evictions/search?${queryParams}`, request);
  }

  getFilters(): Observable<Filters> {
    return this.http.get<Filters>(this.environment.api_prefix + 'api/evictions/filters');
  }

  getOpenEvictions(size: number, page: number, sortBy: string[], sortDirection: string[]): Observable<Results<Eviction>> {
    return this.getEvictions('open', size, page, sortBy, sortDirection);
  }

  getIntakeEvictions(size: number, page: number, sortBy: string[], sortDirection: string[]): Observable<Results<Eviction>> {
    return this.getEvictions('intake', size, page, sortBy, sortDirection);
  }

  getClosedEvictions(size: number, page: number, sortBy: string[], sortDirection: string[]): Observable<Results<Eviction>> {
    return this.getEvictions('closed', size, page, sortBy, sortDirection);
  }

  private getEvictions(status: string, size: number, page: number, sortBy: string[], sortDirection: string[]):
    Observable<Results<Eviction>> {
    let params = `?size=${size}&page=${page}`;
    sortBy.forEach((s, i) => {
      params += `&sort-by=${s}&sort-dir=${sortDirection[i]}`;
    });

    return this.http.get<Results<Eviction>>(`${this.environment.api_prefix}api/evictions/status/${status}${params}`);
  }

  getEvictionByCaseId(caseId: string, queryParams: Params | null = null): Observable<Eviction> {
    const params = {};
    if (queryParams && queryParams.attorneyId) {
      params['attorney-id'] = queryParams.attorneyId;
    }
    return this.http.get<Eviction>(`${this.environment.api_prefix}api/evictions/${caseId}`, {params});
  }

  getEvictionsByClient(orgId: string | undefined): Observable<Eviction[]> {
    return this.http.get<Eviction[]>(`${this.environment.api_prefix}api/organizations/${orgId}/evictions`);
  }

  getETrackerDetailsById(evictionId: string): Observable<Eviction> {
    return this.http.get<Eviction>(`${this.environment.api_prefix}api/evictions/etracker/${evictionId}`);
  }

  getDailyDocketEvictions(startDate: Date, endDate: Date): Observable<CourtTask[]> {
    const from = DateTime.fromJSDate(startDate).toISODate();
    const to = DateTime.fromJSDate(endDate).toISODate();
    return this.http.get<CourtTask[]>(`${this.environment.api_prefix}api/evictions/daily-docket/${from}/${to}`);
  }

  dailyDocketMultiEdit(update: any): Observable<boolean> {
    return this.http.put<boolean>(`${this.environment.api_prefix}api/evictions/daily-docket/multi`, update);
  }

  searchEvictions(searchRequest: EvictionSearch): Observable<Results<Eviction>> {
    return this.http.post<Results<Eviction>>(`${this.environment.api_prefix}api/search/evictions`, searchRequest);
  }

  getPdfAttachments(caseId: string): Observable<any> {
    return this.http.get(`${this.environment.api_prefix}api/evictions/${caseId}/attachments`, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  generateEvictionPrintout(caseId: string, printOptions: EvictionPrintPopupModel,
    queryParams: Params | null = null): Observable<any> {
    const params = {};
    if (queryParams && queryParams.attorneyId) {
      params['attorney-id'] = queryParams.attorneyId;
    }

    return this.http.post(`${this.environment.api_prefix}api/evictions/${caseId}/print`, printOptions, {
      observe: 'response',
      responseType: 'blob',
      params
    });
  }

  assignAttorneyToCase(caseId: string, attorneyUserId: string | null): Observable<Eviction> {
    return this.http.put<Eviction>(`${this.environment.api_prefix}api/evictions/${caseId}/attorney`, attorneyUserId);
  }

  updateEvictionDetails(updatedEviction: Eviction): Observable<Eviction> {
    return this.http.put<Eviction>(`${this.environment.api_prefix}api/evictions/${updatedEviction.case_id}`, updatedEviction);
  }

  saveStepCompletion(payload: StepCompletionModel): Observable<Eviction> {
    return this.http.put<Eviction>(`${this.environment.api_prefix}api/evictions/${payload.case_id}/complete-step`, payload);
  }

  saveClientAnswers(payload: ClientAnswers): Observable<Eviction> {
    return this.http.put<Eviction>(`${this.environment.api_prefix}api/evictions/${payload.case_id}/client-answer`, payload);
  }

  updateAttorneyField(caseId: string, field_name: string, value?: any): Observable<EvictionUpdate> {
    return this.http.put<EvictionUpdate>(`${this.environment.api_prefix}api/evictions/${caseId}/attorney-update`, {
      field_name,
      value
    });
  }

  initScraCheck(caseId: string, tenantId: string): Observable<any> {
    return this.http.put(`${this.environment.api_prefix}api/evictions/scra-check/${caseId}/tenant/${tenantId}`, undefined);
  }
}

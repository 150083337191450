import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CustomFieldType} from '@ee/common/enums';
import {ANY_SNAKE_CASE} from '@ee/common/constants';
import {AvailableFieldValidator} from '@ee/common/validators';
import {Conditions, CustomField, Operator} from '@ee/common/models';
import {CUSTOM_FIELDS} from './custom-fields.constants';
import {ValidateQuillEquation} from '@ee/common/validators';

export type VisibilityRulesForm = FormGroup<{
  field: FormControl<string | undefined>;
  opp: FormControl<Operator | undefined>;
  val: FormControl<string | number | boolean | undefined>;
}>;

export type CustomFieldForm = FormGroup<{
  order: FormControl<number>;
  flex: FormControl<number>;
  label: FormControl<string>;
  hint: FormControl<string>;
  hint_more_info: FormControl<string>;
  field: FormControl<string>;
  type: FormControl<CustomFieldType>;
  required: FormControl<boolean>;
  prevent_removal: FormControl<boolean>;
  link_id: FormControl<string>;
  false_label: FormControl<string>;
  true_label: FormControl<string>;
  include_in_total: FormControl<boolean>;
  hide: FormControl<boolean>;
  hide_from_intake: FormControl<boolean>;
  value: FormControl<any>;
  options: FormControl<string[]>;
  built_in_field: FormControl<boolean>;
  visibility_rules: FormArray<VisibilityRulesForm>,
  visible_rule: FormControl<string>;
  editable_rule: FormControl<string>;
  required_rule: FormControl<string>;
  default_rule: FormControl<string>;
}>;

export function GenerateCustomFieldForm(fb: FormBuilder, customField: CustomField = {} as CustomField): CustomFieldForm {
  let value;
  let required = customField.required;
  switch (customField.type) {
    case CustomFieldType.BOOLEAN:
      value = false;
      break;
    case CustomFieldType.DATE:
      value = new Date();
      break;
    case CustomFieldType.SELECT:
      required = required && customField.options && customField.options.length > 0;
      break;
    default:
      value = null;
  }

  return fb.group({
    order: customField.order,
    flex: customField.flex,
    label: customField.label,
    hint: customField.hint,
    hint_more_info: customField.hint_more_info,
    field: customField.field,
    type: customField.type,
    required: required,
    link_id: customField.link_id,
    false_label: customField.false_label,
    true_label: customField.true_label,
    include_in_total: customField.include_in_total,
    hide: customField.hide,
    hide_from_intake: customField.hide_from_intake,
    built_in_field: customField.built_in_field || CUSTOM_FIELDS.some((f) => f.field === customField.field),
    value: [
      value,
      required && customField.type !== CustomFieldType.BOOLEAN ? [Validators.required] : []
    ],
    options: [customField.options],
    prevent_removal: customField.prevent_removal ?? false,
    visibility_rules: fb.array((customField.visibility_rules ?? []).map((conditions: Conditions) => {
      return GenerateVisibilityRulesForm(fb, conditions);
    })),
    visible_rule: customField.visible_rule,
    editable_rule: customField.editable_rule,
    required_rule: customField.required_rule,
    default_rule: customField.default_rule
  });
}

export function GenerateCustomFieldBuilderForm(fb: FormBuilder, values?: CustomField  ,
  reservedFieldNames: string[] = [], equationEligibleFields: string[] | null = []): CustomFieldForm {
  const visibilityRules = fb.array<VisibilityRulesForm>([]);

  if (values && values.visibility_rules) {
    values.visibility_rules.forEach((conditions: Conditions) => {
      visibilityRules.push(GenerateVisibilityRulesForm(fb, conditions));
    });
  }

  const fieldValidators = [Validators.required, Validators.pattern(ANY_SNAKE_CASE)];
  if (reservedFieldNames.length) {
    fieldValidators.push(AvailableFieldValidator(reservedFieldNames));
  }

  const fieldControl = new FormControl(values?.field ?? undefined, fieldValidators);

  const optionsValidation =
    values?.type === CustomFieldType.SELECT ? [Validators.required, Validators.minLength(1)] : [];

  // convert the following to FormControl syntax
  const form = fb.group({
    value: [{value: undefined, disabled: false}], // only here so custom field component doesn't blow up
    field: fieldControl,
    label: [values?.label ?? '', [Validators.required]],
    hint: values?.hint ?? undefined,
    hint_more_info: values?.hint_more_info ?? undefined,
    order: [values?.order ?? undefined],
    flex: [values?.flex ?? 4, [Validators.required]],
    type: [values?.type ?? undefined, [Validators.required]],
    link_id: values?.link_id ?? undefined,
    required: values?.required ?? false,
    options: [values?.options ?? [], optionsValidation],
    false_label: [values?.false_label ?? undefined],
    true_label: [values?.true_label ?? undefined],
    include_in_total: [values?.include_in_total ?? false],
    hide: [values?.hide ?? false],
    hide_from_intake: [values?.hide_from_intake ?? false],
    prevent_removal: [values?.prevent_removal ?? false],
    built_in_field: [values?.built_in_field || CUSTOM_FIELDS.some((f) => f.field === values?.field)],
    visibility_rules: visibilityRules,
    visible_rule: [{value: values?.visible_rule ?? undefined, disabled: false}],
    editable_rule: [{value: values?.editable_rule ?? undefined, disabled: true}],
    required_rule: [{value: values?.required_rule ?? undefined, disabled: false}],
    default_rule: [{value: values?.default_rule ?? undefined, disabled: true}]
  });

  if (values?.type === CustomFieldType.CALCULATED) {
    form.get('hint')?.disable();
    form.get('hint_more_info')?.disable();
    form.get('value')?.enable();
    if (values?.value) {
      form.get('value').setValue(values.value);
    }
    form.get('value').addValidators([Validators.required, ValidateQuillEquation(equationEligibleFields)]);
  }

  return form;
}

export function GenerateVisibilityRulesForm(fb: FormBuilder, value?: Conditions): VisibilityRulesForm {
  return fb.group({
    field: [value?.field ?? undefined, [Validators.required]],
    opp: [value?.opp ?? undefined, [Validators.required]],
    val: [value?.val ?? undefined, [Validators.required]]
  });
}

import {Injectable} from '@angular/core';
import {BasicField, ConditionParams, ConditionsList, Operator} from '@ee/common/models';


@Injectable({providedIn: 'root'})
export class ConditionEvaluatorService {

  public evaluateConditions(conditions: ConditionsList, obj: any): boolean {
    // for now we're assuming a flat structure/no nested rules and infer AND if multiple rules listed
    // TODO: enhance rule eval logic
    let result = true;
    conditions.forEach((rule: ConditionParams) => {
      const value = this.getFieldValue(rule.field, obj);
      result = result && value !== undefined && this.calculate(value, rule.opp, this.evaluateComparator(rule.val, rule.opp));
    });
    return result;
  }

  public evaluateRuleDefinition(sourceField: BasicField, operator: Operator, matchValue: any, obj: any): boolean {
    const actualValue = this.getFieldValue(sourceField.field, obj);
    return this.calculate(actualValue, operator, matchValue);
  }

  public evaluateRule(rule: string, obj: any): boolean {
    // const [field, opp, val] = rule.split(' ');
    // field is surrounded by hashtags, opp can be found in the OperatorList, val is anything to the right of the opp
    const field = rule.match(/#(.*?)#/)?.[1];
    const opp = rule.match(/(=|<=|>=|>|<|LIKE|IN|NOT IN)/)?.[0];
    const val = rule.match(/(?:=|<=|>=|>|<|LIKE|IN|NOT IN) (.*)/)?.[1];

    const value = this.getFieldValue(field, obj);
    return value !== undefined && this.calculate(value, opp as Operator, this.evaluateComparator(val, opp as Operator));
  }

  private getFieldValue(field: string, obj: any): any {
    let currentPropertyValue = obj;
    field.split('.').forEach((property: string) => {
      if (currentPropertyValue) { // make sure it exists
        currentPropertyValue = currentPropertyValue[property];
      } else {
        currentPropertyValue = undefined;
      }
    });
    return currentPropertyValue;
  }

  private calculate(leftValue: any, operator: Operator, rightValue: any): boolean {
    let result;
    switch (operator) {
      case '==':
        result = leftValue === rightValue;
        break;
      case '<':
        result = leftValue < rightValue;
        break;
      case '>':
        result = leftValue > rightValue;
        break;
      case '<=':
        result = leftValue <= rightValue;
        break;
      case '>=':
        result = leftValue >= rightValue;
        break;
      case 'in':
        result = Array.isArray(rightValue) && rightValue.indexOf(leftValue) > -1;
        break;
      case 'none':
        result = Array.isArray(rightValue) && rightValue.indexOf(leftValue) === -1;
        break;
      case 'contains':
        result = typeof rightValue === 'string' && typeof leftValue === 'string' &&
          rightValue.toLowerCase().includes(leftValue.toLowerCase());
        break;
      case 'not_contains':
        result = typeof rightValue === 'string' && typeof leftValue === 'string' &&
          !rightValue.toLowerCase().includes(leftValue.toLowerCase());
        break;
      case 'starts_with':
        result = typeof rightValue === 'string' && typeof leftValue === 'string' &&
          rightValue.toLowerCase().startsWith(leftValue.toLowerCase());
        break;
      case 'ends_with':
        result = typeof rightValue === 'string' && typeof leftValue === 'string' &&
          rightValue.toLowerCase().endsWith(leftValue.toLowerCase());
        break;
      case 'blank':
        result = leftValue === null || leftValue === undefined || leftValue === '';
        break;
      default:
        result = false;
    }
    return result;
  }

  private evaluateComparator(comp: any, operator: Operator): any {
    let result = comp;
    if (Array.isArray(comp)) {
      result = comp.map(c => this.evaluateComparator(c, operator));
    } else if (typeof comp === 'string') {
      try {
        result = JSON.parse(comp);
      } catch {
        // do nothing
        if (comp?.toLowerCase() === 'true' || comp?.toLowerCase() === 'false') {
          result = JSON.parse(comp);
        } else if (comp?.toLowerCase() === 'null') {
          result = null;
        }
      }
    }
    return result;
  }
}

import {Inject, Injectable, NgZone} from '@angular/core';
import {firstValueFrom, Observable, of} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  NotificationSettings, Organization,
  Results, Tag, ClientSettings, CountyOverrideRule
} from '@ee/common/models';
import {IServerSideGetRowsRequest} from 'ag-grid-enterprise';
import {AccountType} from '@ee/common/enums';

@Injectable({ providedIn: 'root' })
export class ClientService {
  constructor(private http: HttpClient, private zone: NgZone, @Inject('environment') private environment) {
  }

  addNewClient(client: Organization): Observable<Organization> {
    return this.http.post<Organization>(this.environment.api_prefix + 'api/clients', client);
  }

  updateClient(organization: Organization): Observable<Organization> {
    return this.http.put<Organization>(`${this.environment.api_prefix}api/clients`, organization);
  }

  findClients(request: IServerSideGetRowsRequest, accountType: AccountType = AccountType.CLIENT):
    Observable<Results<Organization>> {
    let apiPath = 'api/clients/search';
    if (accountType === AccountType.PROPERTY_MANAGER) {
      apiPath = 'api/property-managers/search';
    }
    return this.http.post<Results<Organization>>(
      this.environment.api_prefix + apiPath, request);
  }

  searchClientsByCompanyName(query: string, fields: string[] = [], accountType: AccountType = AccountType.CLIENT):
    Observable<Organization[]> {
    const params = {};
    if (query) {
      params['q'] = encodeURIComponent(query);
    }
    if (fields?.length) {
      params['f'] = fields.join(',');
    }
    if (accountType === AccountType.PROPERTY_MANAGER) {
      params['account-type'] = AccountType.PROPERTY_MANAGER;
    }

    return this.http.get<Organization[]>(`${this.environment.api_prefix}api/clients/search`, { params });
  }

  getClients(): Observable<Organization[]> {
    return this.http.get<Organization[]>(`${this.environment.api_prefix}api/clients`);
  }

  getClientByIdentifier(identifier: string, fields: string[] = []): Observable<Organization | undefined> {
    const params = {};
    if (fields?.length) {
      params['f'] = fields.join(',');
    }
    return this.http.get<Organization | undefined>(
      this.environment.api_prefix + `api/clients/${identifier}`, { params });
  }

  getClient(id: string): Observable<Organization> {
    return this.http.get<Organization>(`${this.environment.api_prefix}api/clients/${id}?type=id`);
  }

  getClientNotes(identifier: string): Observable<string | undefined> {
    return this.http.get(this.environment.api_prefix + `api/clients/${identifier}/notes`,
      {responseType: 'text'});
  }

  updateClientNotes(identifier: string, notes: string): Observable<boolean> {
    return this.http.put<boolean>(this.environment.api_prefix + `api/clients/${identifier}/notes`, notes);
  }

  updateClientTags(identifier: string, tags: Tag[]): Observable<boolean> {
    const tagIdentifiers = tags.map(t => t.value);
    return this.http.put<boolean>(this.environment.api_prefix + `api/clients/${identifier}/tags`, tagIdentifiers);
  }

  generateClientExport(): Observable<any> {
    return this.http.get(`${this.environment.api_prefix}api/clients/export-client-list`, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  deleteClientById(id: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.environment.api_prefix}api/clients/identifier/${id}`);
  }

  checkClientIdentifierAvailability(identifier: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.environment.api_prefix}api/organizations/check-identifier/${identifier}`);
  }

  updateNotificationSettings(clientId: string, settings: NotificationSettings): Observable<NotificationSettings> {
    return this.http.put<NotificationSettings>(
      `${this.environment.api_prefix}api/clients/${clientId}/notification-settings`, settings);
  }

  getClientSettings(clientId: string | null): Observable<ClientSettings | null> {
    if (!clientId || clientId === 'new') {
      return of(null);
    }
    return this.http.get<ClientSettings | null>(`${this.environment.api_prefix}api/clients/${clientId}/settings`);
  }

  updateClientSetting(clientId: string, setting: string, value: any): Observable<ClientSettings> {
    const requestBody = {};
    requestBody[setting] = value;
    return this.http.put<ClientSettings>(`${this.environment.api_prefix}api/clients/${clientId}/settings`, requestBody);
  }

  addClientCountyOverrideRule(clientId: string, countyOverrideRule: CountyOverrideRule) {
    return this.http.put<boolean>(`${this.environment.api_prefix}api/clients/${clientId}/county`, countyOverrideRule);
  }

  removeClientCountyOverrideRule(clientId: string, countyId: string) {
    return this.http.delete<boolean>(`${this.environment.api_prefix}api/clients/${clientId}/county/${countyId}`);
  }

  async getPlaintiffHistory(clientName: string, clientId: string, attorneyId: string, intakeForm = false):
    Promise<string[]> {
    let plaintiffs = [clientName];
    if (!intakeForm && clientId) {
      // let's handle exceptions gracefully
      try {
        const history = await firstValueFrom(this.http.get<string[]>(
          `${this.environment.api_prefix}api/clients/${clientId}/attorney/${attorneyId}/plaintiffs`));
        plaintiffs = plaintiffs.concat(history);
      } catch (e) {
        console.error(e);
      }
    }
    return plaintiffs;
  }
}

import { NgModule } from '@angular/core';
import { MatButtonLoadingDirective } from './mat-button-loading.directive';
import { DzDirective } from './dz.directive';
import { OptionsScrollDirective } from './options-scroll.directive';
import { TableStickyHeaderDirective } from './table-sticky-header.directive';

@NgModule({
  declarations: [MatButtonLoadingDirective, OptionsScrollDirective, DzDirective, TableStickyHeaderDirective],
  exports: [MatButtonLoadingDirective, OptionsScrollDirective, DzDirective, TableStickyHeaderDirective]
})
export class EeDirectivesModule {}
